import * as d3 from "d3";
import React from "react";
import Section from "./Section.js";
import Jobs from "./Jobs.js";
import useWindowHeight from "./useWindowHeight.js";
import TestbedAnnouncement from "./TestbedAnnouncement.js";
import TestbedHomeButtons from "./TestbedHomeButtons.js";
import I18n from "./I18n";

class TestbedHomeContent extends React.Component {
    constructor(props) {
        super(props);
        this.width = 0;
        this.height = 0;
        this.canvas = null;

        this.resizeCanvas = this.resizeCanvas.bind(this);
        this.collide = this.collide.bind(this);
    }

    componentDidMount() {
        this.init();
        window.addEventListener("resize", this.resizeCanvas);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeCanvas);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.windowHeight !== this.props.windowHeight) {
            setTimeout(this.resizeCanvas, 300);
        }
    }

    init() {
        this.width = d3.select("#cover").node().clientWidth;
        this.height = this.props.windowHeight;

        const nodes = d3.range(200).map(function() {
            return {
                radius: Math.random() * 20 + 2
            };
        });
        const root = nodes[0];
        const color = [
            "#f49e72",
            "#ffffff",
            "#ffffff",
            "#ec6675",
            "#ffffff",
            "#ffffff",
            "#e8bca6"
        ];

        root.radius = 0;
        root.fixed = true;
        root.px = this.width / 2;
        root.py = this.height / 2;

        this.force = d3.layout
            .force()
            .gravity(0.005)
            .charge(function(d, i) {
                return i ? 0 : -400;
            })
            .nodes(nodes)
            .size([this.width, this.height]);

        this.force.start();

        this.canvas = d3
            .select("#cover")
            .append("canvas")
            .attr("width", this.width)
            .attr("height", this.height);

        var context = this.canvas.node().getContext("2d");

        this.force.on("tick", e => {
            const q = d3.geom.quadtree(nodes);
            const n = nodes.length;
            let i, d;

            for (i = 1; i < n; ++i) q.visit(this.collide(nodes[i]));

            context.clearRect(0, 0, this.width, this.height);
            this.force.size([this.width, this.height]);
            for (i = 1; i < n; ++i) {
                context.fillStyle = color[i % color.length];
                d = nodes[i];
                context.moveTo(d.x, d.y);
                context.beginPath();
                context.arc(d.x, d.y, d.radius, 0, 2 * Math.PI);
                context.fill();
            }
        });

        const force = this.force;
        this.canvas.on("mousemove", function() {
            var p1 = d3.mouse(this);
            root.px = p1[0];
            root.py = p1[1];
            force.resume();
        });
    }

    collide(node) {
        var r = node.radius + 160,
            nx1 = node.x - r,
            nx2 = node.x + r,
            ny1 = node.y - r,
            ny2 = node.y + r;

        return (quad, x1, y1, x2, y2) => {
            if (quad.point && quad.point !== node) {
                var x = node.x - quad.point.x,
                    y = node.y - quad.point.y,
                    l = Math.sqrt(x * x + y * y),
                    r = node.radius + quad.point.radius + 10;
                if (l < r) {
                    l = ((l - r) / l) * 0.5;
                    node.x -= x *= l;
                    node.y -= y *= l;
                    quad.point.x += x;
                    quad.point.y += y;
                }
                //Prevent circles in center
                let xCenter = node.x - this.width / 2;
                let yCenter = node.y - this.height / 2;
                let lCenter = Math.sqrt(xCenter * xCenter + yCenter * yCenter);
                let rCenter = node.radius + 500;
                if (lCenter < rCenter) {
                    lCenter = ((lCenter - rCenter) / lCenter) * 0.5;
                    node.x -= xCenter *= lCenter;
                    node.y -= yCenter *= lCenter;
                }
            }
            return x1 > nx2 || x2 < nx1 || y1 > ny2 || y2 < ny1;
        };
    }

    resizeCanvas() {
        this.width = d3.select("#cover").node().clientWidth;
        this.canvas
            .attr("width", this.width)
            .attr("height", this.props.windowHeight);
        this.force.tick();
    }

    render() {
        return (
            <Section
                scrollId="home"
                sectionProps={{
                    id: "intro",
                    className: "slide",
                    "data-name": "home"
                }}
            >
                <div className="content">
                    <div
                        className="container"
                        style={{ minHeight: this.props.windowHeight + "px" }}
                    >
                        <div className="wrap">
                            <div className="fix-10-12">
                                <h2 className="cropBottom ae-1 fromCenter">
                                    <I18n label="TB_MainTitle" />
                                </h2>
                                <br />
                                <h2 className="cropBottom ae-1 fromCenter">
                                    <I18n label="TB_Interested" />?
                                </h2>
                                <br />
                                <TestbedHomeButtons />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    id="cover"
                    className="background customGradient"
                    style={{ minHeight: "695px" }}
                />
            </Section>
        );
    }
}

const TestbedHome = props => {
    const { windowHeight } = useWindowHeight();
    return <TestbedHomeContent {...props} windowHeight={windowHeight} />;
};
export default TestbedHome;
