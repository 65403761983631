import React from "react";
import Content from "./Content.js";
import Section from "./Section.js";
import { sponsors } from "../data/testbed";
import I18n from "./I18n.js";

const Item = props => {
    const sponsor = (
        <div style={{ padding: "40px 35px" }}>
            <img
                alt={props.sponsor.name}
                src={props.sponsor.image}
                width="200"
            />
        </div>
    );
    if (props.sponsor.url) {
        return (
            <a href={props.sponsor.url} target="_blank">
                {sponsor}
            </a>
        );
    } else {
        return sponsor;
    }
};

const TestbedSponsors = props => {
    return (
        <>
            <Section
                scrollId="sponsors"
                sectionProps={{
                    className: "slide whiteSlide member",
                    "data-name": "sponsors"
                }}
            >
                <Content>
                    <div className="container">
                        <div className="wrap">
                            <div className="fix-10-12 toCenter">
                                <h1 className="ae-1 fromLeft header-11 light">
                                    <I18n label="TB_SupportedBy" />
                                </h1>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}
                            >
                                {sponsors.map(sponsor => {
                                    return (
                                        <Item
                                            key={sponsor.name}
                                            sponsor={sponsor}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </Content>
            </Section>
        </>
    );
};

export default TestbedSponsors;
