import _ from "underscore";
import Close from "./svg/Close.js";
import React from "react";
import PropTypes from "prop-types";
import { testbedCategories } from "../data/testbed";
import CategoryFilterDropdown from "./CategoryFilterDropdown.js";
import LocaleContext from "./LocaleContext.js";

const CloseIconSize = "24px";

const FilterItem = props => {
    const { handleToggle, item } = props;
    const { locale } = React.useContext(LocaleContext);
    return (
        <div
            style={{
                margin: "5px",
                padding: "6px 10px",
                paddingLeft: "8px",
                color: "#000",
                backgroundColor: "#eee",
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <div
                onClick={handleToggle}
                style={{
                    cursor: "pointer",
                    width: CloseIconSize,
                    height: CloseIconSize,
                    marginRight: "6px"
                }}
            >
                <Close />
            </div>
            {item.name[locale]}
        </div>
    );
};

const ActiveFilters = props => {
    const { filterIds, setFilterIds } = props;
    if (_.isEmpty(filterIds)) return null;
    const filtersDict = _.reduce(
        testbedCategories,
        (memo, category) => {
            _.each(category.values, item => {
                memo[item.id] = item;
            });
            return memo;
        },
        {}
    );
    return (
        <>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexWrap: "wrap"
                }}
            >
                {_.map(filterIds, filterId => (
                    <FilterItem
                        handleToggle={() => {
                            setFilterIds(_.without(filterIds, filterId));
                        }}
                        item={filtersDict[filterId]}
                    />
                ))}
                <div>
                    <a
                        style={{
                            marginLeft: "12px",
                            color: "#ec6675",
                            fontSize: "1em",
                            fontWeight: "bold"
                        }}
                        onClick={() => setFilterIds([])}
                    >
                        Alle filter zurücksetzen
                    </a>
                </div>
            </div>
        </>
    );
};

const TestbedFilter = props => {
    const { filterIds, setFilterIds } = props;
    const { locale } = React.useContext(LocaleContext);
    return (
        <>
            <div
                style={{
                    display: "flex",
                    alignItems: "flex-center",
                    justifyContent: "center"
                }}
            >
                {_.map(testbedCategories, category => (
                    <div key={category.id} style={{ margin: "8px" }}>
                        <CategoryFilterDropdown
                            key={category.id}
                            label={category.name[locale]}
                            items={_.map(category.values, value => {
                                return {
                                    id: value.id,
                                    name: value.name[locale]
                                };
                            })}
                            filterIds={filterIds}
                            setFilterIds={setFilterIds}
                        />
                    </div>
                ))}
            </div>
            <ActiveFilters filterIds={filterIds} setFilterIds={setFilterIds} />
        </>
    );
};

TestbedFilter.propTypes = {
    filterIds: PropTypes.array,
    setFilterIds: PropTypes.func.isRequired
};

export default TestbedFilter;
