import _ from "underscore";
import React, { useState } from "react";
import "./multiSelectDropdown.css";

const MultiSelectDropdown = props => {
    const { label, options, selectedOptions, toggleOption } = props;
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = React.useRef(null);

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleClickOutside = event => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target)
        ) {
            setIsOpen(false);
        }
    };

    React.useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div
            className="multi-select-dropdown"
            style={{ position: "relactive", display: "inline-block" }}
            ref={dropdownRef}
        >
            <button onClick={toggleDropdown}>{label}</button>
            {isOpen && (
                <ul className="dropdown-menu">
                    {_.map(options, option => (
                        <li key={option.id} style={{ padding: "4px 0" }}>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={selectedOptions.includes(
                                        option.id
                                    )}
                                    onChange={() => toggleOption(option.id)}
                                    style={{ marginRight: "8px" }}
                                />
                                <div className="custom-checkbox"></div>
                                <div>{option.name}</div>
                            </label>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default MultiSelectDropdown;
