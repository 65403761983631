import MainLayout from "../components/MainLayout.js";
import Nav from "../components/Nav.js";
import React from "react";
import TestbedAbout from "../components/TestbedAbout.js";
import TestbedHowItWorks from "../components/TestbedHowItWorks.js";
import TestbedAvailableTests from "../components/TestbedAvailableTests.js";
import TestbedContact from "../components/TestbedContact.js";
import TestbedHome from "../components/TestbedHome.js";
import TestbedSponsors from "../components/TestbedSponsors";
import LocaleProvider from "../components/LocaleProvider.js";
import TestbedExperienceFromPractice from "../components/TestbedExperienceFromPractice.js";

const TestbedPage = props => {
    React.useEffect(() => {
        if (!location?.hash) return;
        setTimeout(() => {
            if (location.hash) {
                location.href = location.hash;
            }
        }, 200);
    });
    return (
        <LocaleProvider>
            <MainLayout>
                <Nav darkMenu={false} isTestbed />
                <TestbedHome />
                <TestbedAbout />
                <TestbedHowItWorks />
                <TestbedExperienceFromPractice />
                <TestbedAvailableTests />
                <TestbedContact />
                <TestbedSponsors />
            </MainLayout>
        </LocaleProvider>
    );
};

export default TestbedPage;
