import _ from "underscore";
import React from "react";
import Section from "./Section.js";
import Members from "./Members.js";
import { availableTests, testbedContactLinkUrl } from "../data/testbed";
import TestbedFilter from "./TestbedFilter";
import { testbedCategories } from "../data/testbed";
import I18n from "./I18n.js";
import LocaleContext from "./LocaleContext.js";

const filterByLocale = ({ availableTests, locale }) => {
    if (!locale) return availableTests;
    return _.filter(availableTests, test => {
        if (_.isEmpty(test.languages)) {
            return true;
        }
        return _.contains(test.languages, locale);
    });
};

const filterTests = ({ filterIds, availableTests, locale }) => {
    const localeTests = filterByLocale({ availableTests, locale });
    if (_.isEmpty(filterIds)) {
        return localeTests;
    }
    const filterCategoryDict = _.reduce(
        testbedCategories,
        (memo, category) => {
            _.each(category.values, item => {
                memo[item.id] = category.id;
            });
            return memo;
        },
        {}
    );
    const filterIdsByCategory = _.groupBy(
        filterIds,
        filterId => filterCategoryDict[filterId]
    );
    return _.filter(localeTests, test => {
        if (_.isEmpty(test.testbedCategories)) {
            return null;
        }
        return _.every(filterIdsByCategory, categoryFilterIds => {
            return !_.isEmpty(
                _.intersection(categoryFilterIds, test.testbedCategories)
            );
        });
    });
};

const translateTests = ({ tests, locale }) => {
    return _.map(tests, test => {
        return {
            ...test,
            description: (test.description && test.description[locale]) || "",
            logo: (test.logo && test.logo[locale]) || "",
            url: test.url && test.url[locale]
        };
    });
};

const TestbedAvailableTests = props => {
    const [filterIds, setFilterIds] = React.useState([]);
    const { locale } = React.useContext(LocaleContext);
    const filteredTests = filterTests({ filterIds, availableTests, locale });
    const tests = translateTests({ tests: filteredTests, locale });
    return (
        <>
            <Section
                scrollId="tests"
                sectionProps={{
                    className: "slide whiteSlide member",
                    "data-name": "tests"
                }}
            >
                <Members
                    header={
                        <>
                            <div
                                style={{
                                    fontSize: "x-large",
                                    marginTop: "12px",
                                    marginBottom: "24px"
                                }}
                            >
                                <I18n label="TB_AvailableTestsText" />
                            </div>
                            <div
                                style={{
                                    marginBottom: "36px"
                                }}
                            >
                                <TestbedFilter
                                    filterIds={filterIds}
                                    setFilterIds={setFilterIds}
                                />
                            </div>
                        </>
                    }
                    locale="de"
                    contactLinkEnabled
                    contactLinkLabel={<I18n label="TB_Register" />}
                    contactLinkUrl={testbedContactLinkUrl[locale]}
                    members={tests}
                    noCategories
                    title={<I18n label="TB_AvailableTests" />}
                    withTranslation
                />
            </Section>
        </>
    );
};

export default TestbedAvailableTests;
