import React from "react";
import I18n from "./I18n";
import LocaleContext from "./LocaleContext.js";
import { testbedContactLinkUrl } from "../data/testbed";

const styles = {
    root: {
        display: "flex",
        justifyContent: "center",
        marginTop: "16px"
    },
    content: {
        border: "1px solid #ffff",
        padding: "8px 16px",
        fontSize: "35px"
    },
    subtitle: {
        fontSize: "0.8em"
    },
    buttonsRoot: {
        marginTop: "16px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        flexWrap: "wrap"
    },
    button: {
        backgroundColor: "#fff",
        color: "#000",
        padding: "8px 16px",
        margin: "2px",
        width: "200px",
        cursor: "pointer"
    }
};

const TestbedHomeButtons = () => {
    const { locale } = React.useContext(LocaleContext);
    return (
        <>
            <div style={styles.root}>
                <a href={testbedContactLinkUrl[locale]} target="_blank">
                    <div style={styles.content}>
                        <I18n label="TB_Registration" />
                    </div>
                </a>
            </div>
            <div style={styles.root}>
                <a href="/testbed/#about">
                    <div style={styles.content}>
                        <I18n label="TB_MoreInfo" />
                    </div>
                </a>
            </div>
            <div style={styles.root}>
                <a href="/testbed/#experienceFromPractice">
                    <div style={styles.content}>
                        <I18n label="TB_ExperienceFromPractice" />
                    </div>
                </a>
            </div>
            <div style={styles.root}>
                <a
                    href="https://www.edtech-collider.ch/testbed/#tests"
                    target="_blank"
                >
                    <div style={styles.content}>
                        <I18n label="TB_OpenTestbeds" />
                    </div>
                </a>
            </div>
            <div style={styles.root}>
                <a href="http://eepurl.com/ieOYur" target="_blank">
                    <div style={styles.content}>
                        <I18n label="TB_Newsletter" />
                    </div>
                </a>
            </div>
        </>
    );
};

export default TestbedHomeButtons;
