import React from "react";
import Section from "./Section.js";
import I18n from "./I18n.js";
import LocaleContext from "./LocaleContext.js";
import { testbedContactLinkUrl } from "../data/testbed.js";

const ContactItem = props => {
    const { title, content, buttonLabel, buttonUrl } = props;
    const contactStyle = {
        marginBottom: "16px",
        fontSize: "24px",
        fontWeight: 400
    };
    return (
        <>
            <div className="fix-10-12 toCenter">
                <h1 className="ae-1 fromLeft header-11 light">{title}</h1>
            </div>
            <div className="fix-11-12">
                <div className="ae-2 light">{content}</div>
            </div>
            <div className="fix-11-12">
                <div className="ae-2 light">
                    <div style={contactStyle}>
                        <a
                            href={buttonUrl}
                            target="_blank"
                            style={{
                                backgroundColor: "#f08073",
                                padding: "12px 24px",
                                color: "#fff"
                            }}
                        >
                            {buttonLabel}
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
};

const TestbedContact = props => {
    const { locale } = React.useContext(LocaleContext);
    return (
        <Section
            scrollId="contact"
            sectionProps={{
                className: "slide whiteSlide",
                "data-name": "contact"
            }}
        >
            <div className="content">
                <div className="container">
                    <div className="wrap">
                        <ContactItem
                            title={<I18n label="TB_Questions" />}
                            content={<I18n label="TB_Contact" markdown />}
                            buttonLabel={<I18n label="TB_ContactBtn" />}
                            buttonUrl="mailto:verena.rohrer@edtech-collider.ch"
                        />
                        <br />
                        <br />
                        <br />
                        <br />
                        <ContactItem
                            title={<I18n label="TB_Interested" />}
                            content={<I18n label="TB_Apply" markdown />}
                            buttonLabel={<I18n label="TB_ApplyBtn" />}
                            buttonUrl={testbedContactLinkUrl[locale]}
                        />
                        <br />
                        <br />
                        <br />
                        <br />
                        <ContactItem
                            title={<I18n label="TB_StayInformed" />}
                            content={<I18n label="TB_NewsletterAbo" markdown />}
                            buttonLabel={<I18n label="TB_NewsletterAboBtn" />}
                            buttonUrl="http://eepurl.com/ieOYur"
                        />
                    </div>
                </div>
            </div>
        </Section>
    );
};

export default TestbedContact;
