import _ from "underscore";
import React from "react";
import MultiSelectDropdown from "./MultiSelectDropdown";

const Title = props => {
    const { label, selectedCount } = props;
    if (selectedCount > 0) {
        return (
            <span>
                {label} {`(${selectedCount})`}
            </span>
        );
    }
    return label;
};

const CategoryFilterDropdown = props => {
    const { filterIds, label, items, setFilterIds } = props;
    const filtersDict = _.indexBy(filterIds);
    const selectedCount = _.size(
        _.filter(items, item => !!filtersDict[item.id])
    );
    return (
        <MultiSelectDropdown
            label={<Title {...props} selectedCount={selectedCount} />}
            options={items}
            selectedOptions={filterIds}
            toggleOption={filterId => {
                if (_.contains(filterIds, filterId)) {
                    setFilterIds(_.without(filterIds, filterId));
                } else {
                    setFilterIds([...filterIds, filterId]);
                }
            }}
        />
    );
};

export default CategoryFilterDropdown;
